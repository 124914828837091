import React, { useState } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import classnames from "classnames";
import RedirectLink from "../RedirectLink/RedirectLink";
import Link from "../Link/Link";
import { DistrictProps } from ".";
import {
    getBbClassDetailsLink,
    getBbClassListLink,
    getEducatorLink,
    getImpersonateUrl,
    getOidcRedirectUri,
} from "../../shared/config";
import api from "../../services/api";
import "./District.scss";
import {
    keycloakConfig,
    keycloakInstance,
} from "../../providers/AuthOidcProvider";
import Keycloak from "keycloak-js";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { IS_SESSION_INITIALIZING } from "../../shared/constants";
import SessionCard from "./SessionCard";
const GroupsSkeletonLoader = () => (
    <>
        <li>
            <span className="tutoring-portal-session-card skeleton"></span>
        </li>
    </>
);

const TextSkeletonLoader = () => (
    <div
        className="totoring-portal-join-session skeleton"
        style={{
            width: 150,
            height: 25,
            marginLeft: 10,
            borderRadius: 4,
        }}
    ></div>
);

const District: React.FC<DistrictProps> = ({
    accessToken,
    district,
    districts,
    districtKey,
    activeDistrictKey,
    onRedirectLinkClick,
    onLogout,
    setLoaderScreenVisible,
    email,
    isTodayFilterEnabled,
    isLoading
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [bbSession, setBBSession] = useState<null | {
        name: string;
        id: number;
    }>(null);

    const isActive = districtKey === activeDistrictKey;
    const otherDistrictIsActive = activeDistrictKey !== undefined && !isActive;
    const targetType = "teacher";
    const initializeKeycloakSession = () => {
        if (!keycloakInstance.authenticated) {
            const instance = new Keycloak(keycloakConfig);
            sessionStorage.setItem(IS_SESSION_INITIALIZING, "true");

            instance
                .init({
                    onLoad: "check-sso",
                    silentCheckSsoRedirectUri: `${getOidcRedirectUri()}/silent-check-sso.html`,
                })
                .then((initialized) => {
                    if (initialized) {
                        sessionStorage.removeItem(IS_SESSION_INITIALIZING);
                        window.location.reload();
                    } else {
                        setTimeout(() => {
                            initializeKeycloakSession();
                        }, 1000);
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        initializeKeycloakSession();
                    }, 1000);
                });
        }
    };

    const getBBSessions = async () => {
        const redisSessionsResponse = await api.tutoringApi.getBBUserSessions(
            email
        );

        if (redisSessionsResponse.data.results.length < 1)
            return {
                data: {
                    results: [],
                },
            };

        return await api.tutoringApi.bbUserSessions(
            redisSessionsResponse.data.results.map((session) =>
                Number(session.user.id.split(":")[2])
            ),
            email
        );
    };

    const onGroupClick = async (
        event: any,
        id: number,
        externalId?: string
    ) => {
        event.preventDefault();

        setLoaderScreenVisible?.(true);
        let courseDetails;
        let bbSessionResponse;

        if (externalId) {
            bbSessionResponse = await getBBSessions();
        } else {
            [bbSessionResponse, courseDetails] = await Promise.all([
                getBBSessions(),
                api.tutoringApi.courseDetails(id),
            ]);
        }

        const sessions = bbSessionResponse.data.results;

        const isBBSessionExist = sessions.length > 0;

        if (isBBSessionExist) {
            const isBBSessionSameAsDistrict = !!sessions.find(
                (session) =>
                    // ExternalId format is "urn:mclass-staff:<amp_user_sid>"
                    Number(session.user.externalId.split(":")[2]) ===
                    district.amp_user_sid
            );

            if (isBBSessionSameAsDistrict) {
                event.target.children.redirect_uri.value =
                    getBbClassDetailsLink(
                        externalId ?? courseDetails?.data.external_id
                    );
                api.tutoringApi.writeBBUserSession(
                    email,
                    district.amp_user_sid
                );
                datadogLogs.logger.info(
                    `Blackboard Classes session has started for ${district.email}`
                );
                event.target.submit();
                initializeKeycloakSession();
                onRedirectLinkClick();
            } else {
                const activeSession = districts.find(
                    (dist) =>
                        Number(sessions[0].user.externalId.split(":")[2]) ===
                        dist.amp_user_sid
                );

                setBBSession({
                    name: activeSession?.district_name as string,
                    id: activeSession?.amp_user_sid as number,
                });
                setShowPopup(true);
            }
        } else {
            if (!isActive) {
                event.target.children.redirect_uri.value =
                    getBbClassDetailsLink(
                        externalId ?? courseDetails?.data.external_id
                    );
                api.tutoringApi.writeBBUserSession(
                    email,
                    district.amp_user_sid
                );
                datadogLogs.logger.info(
                    `Blackboard Classes session has started for ${district.email}`
                );
                event.target.submit();
                initializeKeycloakSession();
                onRedirectLinkClick();
            } else {
                datadogLogs.logger.info(
                    `Blackboard Classes page opens for ${district.email}`
                );
                window.open(
                    getBbClassDetailsLink(
                        externalId ?? courseDetails?.data.external_id
                    )
                );
            }
        }

        setLoaderScreenVisible?.(false);
    };

    const onEducatorHomeClick = async (event: any) => {
        event.preventDefault();

        setLoaderScreenVisible?.(true);

        const response = await getBBSessions();
        const sessions = response.data.results;

        const isBBSessionExist = sessions.length > 0;

        if (isBBSessionExist) {
            const isBBSessionSameAsDistrict = !!sessions.find(
                (session) =>
                    // ExternalId format is "urn:mclass-staff:<amp_user_sid>"
                    Number(session.user.externalId.split(":")[2]) ===
                    district.amp_user_sid
            );

            if (isBBSessionSameAsDistrict) {
                if (!isActive) {
                    api.tutoringApi.writeBBUserSession(
                        email,
                        district.amp_user_sid
                    );
                    datadogLogs.logger.info(
                        `Educator home session has started for ${district.email}`
                    );
                    event.target.submit();
                    initializeKeycloakSession();
                    onRedirectLinkClick();
                } else {
                    window.open(getEducatorLink());
                }
            } else {
                const activeSession = districts.find(
                    (dist) =>
                        Number(sessions[0].user.externalId.split(":")[2]) ===
                        dist.amp_user_sid
                );

                setBBSession({
                    name: activeSession?.district_name as string,
                    id: activeSession?.amp_user_sid as number,
                });
                setShowPopup(true);
            }
        } else {
            if (!isActive) {
                api.tutoringApi.writeBBUserSession(
                    email,
                    district.amp_user_sid
                );
                datadogLogs.logger.info(
                    `Educator home session has started for ${district.email}`
                );
                event.target.submit();
                initializeKeycloakSession();
                onRedirectLinkClick();
            } else {
                window.open(getEducatorLink());
            }
        }

        setLoaderScreenVisible?.(false);
    };

    const onClassListClick = async (event: any) => {
        event.preventDefault();

        setLoaderScreenVisible?.(true);

        const response = await getBBSessions();
        const sessions = response.data.results;

        const isBBSessionExist = sessions.length > 0;

        if (isBBSessionExist) {
            const isBBSessionSameAsDistrict = !!sessions.find(
                (session) =>
                    // ExternalId format is "urn:mclass-staff:<amp_user_sid>"
                    Number(session.user.externalId.split(":")[2]) ===
                    district.amp_user_sid
            );

            if (isBBSessionSameAsDistrict) {
                api.tutoringApi.writeBBUserSession(
                    email,
                    district.amp_user_sid
                );
                datadogLogs.logger.info(
                    `Blackboard Classes session has started for ${district.email}`
                );
                event.target.submit();
                initializeKeycloakSession();
                onRedirectLinkClick();
            } else {
                const activeSession = districts.find(
                    (dist) =>
                        Number(sessions[0].user.externalId.split(":")[2]) ===
                        dist.amp_user_sid
                );

                setBBSession({
                    name: activeSession?.district_name as string,
                    id: activeSession?.amp_user_sid as number,
                });
                setShowPopup(true);
            }
        } else {
            if (!isActive) {
                api.tutoringApi.writeBBUserSession(
                    email,
                    district.amp_user_sid
                );
                datadogLogs.logger.info(
                    `Blackboard Classes session has started for ${district.email}`
                );
                event.target.submit();
                initializeKeycloakSession();
                onRedirectLinkClick();
            } else {
                datadogLogs.logger.info(
                    `Blackboard Classes page opens for ${district.email}`
                );
                window.open(getBbClassListLink());
            }
        }

        setLoaderScreenVisible?.(false);
    };

    return (
        <div
            className={classnames("tutoring-portal-district", {
                active: isActive,
            })}
        >
            <ConfirmationModal
                onClose={() => {
                    setShowPopup(false);
                    setBBSession(null);
                }}
                onAction={() => {
                    if (bbSession)
                        api.tutoringApi.deleteBBUserSession(
                            email,
                            bbSession.id
                        );
                    window.open(getBbClassListLink());
                    setShowPopup(false);
                    setBBSession(null);
                }}
                isLoading={false}
                isStartButtonDisabled={false}
                isOpen={showPopup}
                title={`Log out of Blackboard for ${bbSession?.name}`}
            >
                {`You are logged into ${bbSession?.name}. Please log out to access ${district.district_name}.`}
            </ConfirmationModal>
            <div className="tutoring-portal-district-header tutoring-portal-border-bottom">
                <h3>{district.district_name}</h3>
                <div className="tutoring-portal-active-district-wrapper">
                    {isActive ? (
                        <React.Fragment>
                            <span className="active-label">
                                <span className="dot"></span>
                                ACTIVE DISTRICT
                            </span>
                            <Link
                                type="default"
                                onClick={onLogout}
                                classNames="cursor-pointer logout-link"
                            >
                                Log out of this district
                            </Link>
                        </React.Fragment>
                    ) : null}
                </div>
                <RedirectLink
                    action={getImpersonateUrl()}
                    kcAccessToken={accessToken as string}
                    targetAmpUserSid={district.amp_user_sid}
                    targetUserType={targetType}
                    redirectUri={getEducatorLink()}
                    type="button"
                    onClick={onEducatorHomeClick}
                    disabled={otherDistrictIsActive}
                >
                    <i className="icon-v2-home"></i>
                    Educator Home
                </RedirectLink>
                <RedirectLink
                    action={getImpersonateUrl()}
                    kcAccessToken={accessToken as string}
                    targetAmpUserSid={district.amp_user_sid}
                    targetUserType={targetType}
                    redirectUri={getBbClassListLink()}
                    type="button"
                    onClick={onClassListClick}
                    disabled={otherDistrictIsActive}
                    classNames="btn-secondary"
                >
                    See all my groups
                </RedirectLink>
            </div>
            {isLoading ? (
                <TextSkeletonLoader />
            ) : (
                  district.enrollments?.filter((enrollment) =>
                      isTodayFilterEnabled ? enrollment.hasSessionToday : true
                  ) as []
              ).length > 0 ? (
                <div className="totoring-portal-join-session">
                    Join group session
                </div>
            ) : (
                <div className="totoring-portal-join-session">
                    No groups available
                </div>
            )}

            <ul className="tutoring-portal-sessions">
                {isLoading ? (
                    <GroupsSkeletonLoader />
                ) : (
                    district.enrollments
                        ?.filter((enrollment) =>
                            isTodayFilterEnabled
                                ? enrollment.hasSessionToday
                                : true
                        )
                        .map((enrollment) => (
                            <li
                                key={`${enrollment.course?.id}_${enrollment.course?.title}`}
                            >
                                <SessionCard
                                    disabled={
                                        otherDistrictIsActive
                                    }
                                    enrollment={enrollment}
                                    onClick={(event) => {
                                        onGroupClick(
                                            event,
                                            enrollment.course?.id,
                                            enrollment.course?.external_id
                                        );
                                    }}
                                    action={getImpersonateUrl()}
                                    kcAccessToken={accessToken as string}
                                    targetAmpUserSid={district.amp_user_sid}
                                    targetUserType={targetType}
                                    redirectUri=""
                                />
                            </li>
                        ))
                )}
            </ul>
        </div>
    );
};

export default District;