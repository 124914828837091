import {AxiosInstance} from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import {GetAllEnrolledCourses, MappedAccount, NearestSession} from "./index";
import {SyncSubstituteDTO} from "../dto/SyncSubstituteDTO";
import { ExceptionsGetHolidaysResponse } from '../components/Districts';
import { chunkArray } from '../utils/helpers';

const tutoringApi = (axios: AxiosInstance) => ({
    enrollments: async (ampUserSids: number[]) => {
        try {
            const ids = ampUserSids.map(id => `urn:mclass-staff:${id}`)
            const result = await axios.get<GetAllEnrolledCourses>('course-user', {
                params: {
                    user_ids: ids.join(','),
                    pagination: false
                }
            });

            return result;
        } catch (error) {
            datadogLogs.logger.error(`District enrollments loading failed for user ids: ${ampUserSids.join(',')}`, {
                user_id: `urn:mclass-staff:${ampUserSids}`
            }, error as Error);
            throw error;
        }
    },
    courseDetails: async (id: number) => {
        return await axios.get(`course/${id}`);
    },
    bbUserSessions: async (ids: number[], masterEmail: string)=> {
        return await axios.post<{ results: { user: { externalId: string } }[] }>('user/blackboard-sessions', {
            users: ids.map(id => ({ id: `urn:mclass-staff:${id}`, email: masterEmail }))
        })
    },
    bbUserSessionsAsync: async (ids: number[] | string[], masterEmail: string)=> {
        return await axios.post<{ results: { user: { externalId: string } }[] }>('user/blackboard-sessions?async=true', {
            users: ids.map(id => ({ id: `urn:mclass-staff:${id}`, email: masterEmail }))
        })
    },
    getBBUserSessions: async (email: string) => {
        const base64Email = btoa(email);
        return await axios.get<{ results: { user: { id: string } }[] }>(`user/blackboard-sessions/${base64Email}`);
    },
    writeBBUserSession: async (masterEmail: string, id: number) => {
        return await axios.post('user/blackboard-sessions/report', {
            operation: 'add',
            user: {
                email: masterEmail,
                id: `urn:mclass-staff:${id}`
            }
        });
    },
    deleteBBUserSession: async (masterEmail: string, id: number) => {
        return await axios.post('user/blackboard-sessions/report', {
            operation: 'delete',
            user: {
                email: masterEmail,
                id: `urn:mclass-staff:${id}`
            }
        });
    },
    syncSubstitutes: async (users: MappedAccount[]) => {
        return await axios.post('user/schedule-subs', {
            users: SyncSubstituteDTO.transformToRequest(users)
        })
    },
    getDistrictDetails: async (ids: number[]) => {
        return await axios.get(`institution`,  {
            params: {
                inst_uids: ids.join(','),
                pagination: false
            }
        })
    },
    getScheduledExceptions: async(id: number) => {
        return await axios.get<ExceptionsGetHolidaysResponse>('holiday', {
            params: {
                institution_id: id
            },
        })
    },
    getNearestSession: async (course_ids: number[]) => {
        if (course_ids.length > 300) {
            //keep URL length below 2048
            const chunkedArray = chunkArray(course_ids, 300);
            const requests = chunkedArray.map((chunk) =>
                axios.get<{ results: NearestSession[] }>(
                    `session/nearest?course_ids=${chunk.join(",")}`
                )
            );
            const results = (await Promise.all(requests))
                .map((data) => data.data.results)
                .flat();
            return { data: { results: results } };
        } else {
            return await axios.get<{ results: NearestSession[] }>(
                `session/nearest?course_ids=${course_ids.join(",")}`
            );
        }
    },
});

export default tutoringApi;
