import axios, { InternalAxiosRequestConfig } from "axios";
import { getImpersonateUrl, getTutoringBffUrl, getTutoringApiUrl, getSecretKey } from "../shared/config";
import { enc, HmacSHA1, SHA256 } from 'crypto-js';
import { isEnabled } from './featureFlags';

export const axiosBffInstance = axios.create({
    baseURL: getTutoringBffUrl(),
});

export const axiosImpersonateInstance = axios.create({
    baseURL: getImpersonateUrl(),
});

export const axiosTutoringApiInstance = axios.create({
    baseURL: getTutoringApiUrl(),
});

const addHmacToRequest = (config: InternalAxiosRequestConfig) => {
    const username = 'tutoring_api_user';
    const secretKey = enc.Base64.parse(getSecretKey()).toString(enc.Utf8);
    const method = config.method?.toUpperCase();
    const url = `${config.baseURL}${config.url}`;
    const body = config.data;
    const timestamp = new Date().toISOString().split('.')[0] + 'Z';
    const hmacSignature = genHmacSignature(secretKey, method, url, timestamp, username, body);

    config.headers.Authorization = `hmac ${username}:${hmacSignature}`;
    config.headers['x-hmac-timestamp'] = timestamp;
    return config;
};

function genHmacSignature(secretKey: string, httpVerb: string | undefined, url: string, timestamp: string, requestorName: string, data: any = null) {
    try {
        const parsedUrl = new URL(url);
        const path = parsedUrl.pathname;
        const reqSign = [httpVerb?.toUpperCase(), path, timestamp, requestorName];
        const emptyRequestDataValues = [null, undefined, ''];
        if (!emptyRequestDataValues.includes(data)) {
            if (typeof data !== 'string') {
                data = JSON.stringify(data);
            }
            const dataHash = SHA256(
                enc.Utf8.parse(data)
            ).toString(enc.Hex);

            reqSign.push(dataHash);
        }
        const digest = HmacSHA1(reqSign.join('+'), secretKey);
        return enc.Base64.stringify(digest);
    } catch (error) {
        console.error('Error generating HMAC signature:', error);
        throw error;
    }
}

if (isEnabled('hmacAuthentication')) {
axiosTutoringApiInstance.interceptors.request.use(addHmacToRequest);
axiosBffInstance.interceptors.request.use(addHmacToRequest);
}