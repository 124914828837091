export enum Envs {
    Local = 'local',
    Ci = 'ci',
    Staging = 'staging',
    Production = 'production',
}

const ENVIRONMENT = 'production';
export const DATADOG_PUBLIC_API_KEY = 'pubf9da9f198392b2e6d30f44bd458697f0';

export const getEnv = (): Envs =>
    (ENVIRONMENT.startsWith('{creds') ? (process.env.REACT_APP_ENV || Envs.Local) : ENVIRONMENT) as Envs;

const getEnvValue = (ci: string, staging: string, prod: string) => {
    const env = getEnv();

    return env === Envs.Production
        ? prod
        : env === Envs.Staging
            ? staging
            : ci;
};

export const getTutoringBffUrl = () => (
    getEnvValue(
        'https://tutoring-bff.aws.wgen.net/tutoring-bff/v1/',
        'https://tutoring-bff.staging.wgen.net/tutoring-bff/v1/',
        'https://tutoring-bff.production.wgen.net/tutoring-bff/v1/'
    )
);

export const getOidcAuthority = (realm: string = 'master') => (
    getEnvValue(
        `https://my-devci.poc.learning.amplify.com/auth/realms/${realm}`,
        `https://my-staging.stage.learning.amplify.com/auth/realms/${realm}`,
        `https://my.amplify.com/auth/realms/${realm}`
    )
);

export const getKeycloakAuthority = () => (
    getEnvValue(
        `https://my-devci.poc.learning.amplify.com/auth/`,
        `https://my-staging.stage.learning.amplify.com/auth/`,
        `https://my.amplify.com/auth/`
    )
);

export const getOidcClientId = () => (
    'tutor-portal'
);

export const getOidcRedirectUri = () => (
    window.location.origin
);

export const getImpersonateUrl = (realm: string = 'Amplify') => (
    getOidcAuthority(realm) + '/impersonation-proxy/impersonate'
);

export const getEducatorLink = () => (
    getEnvValue(
        'https://learning-devci.poc.learning.amplify.com/home/educator/',
        'https://learning-staging.stage.learning.amplify.com/home/educator/',
        'https://learning.amplify.com/home/educator/'
    )
);

export const getBbClassListLink = () => (
    getEnvValue(
        'http://tutor-test.poc.learning.amplify.com/',
        'https://tutor.amplify.com/',
        'https://tutor.amplify.com/'
    )
);

export const getBbClassDetailsLink = (bbInternalId: string | number) => (
    getEnvValue(
        `${getBbClassListLink()}ultra/courses/${bbInternalId}/outline`,
        `${getBbClassListLink()}ultra/courses/${bbInternalId}/outline`,
        `${getBbClassListLink()}ultra/courses/${bbInternalId}/outline`
    )
);

export const getTutoringApiUrl = () => (
    getEnvValue(
        'https://tutoring-api.aws.wgen.net/tutoring-api/v1/',
        'https://tutoring-api.staging.wgen.net/tutoring-api/v1/',
        'https://tutoring-api.production.wgen.net/tutoring-api/v1/'
    )
);

export const getTutoringCollaborateApiUrl = () => (
    getEnvValue(
        'https://tutoring-api.aws.wgen.net/tutoring-collaborate-api/v1/',
        'https://tutoring-api.staging.wgen.net/tutoring-collaborate-api/v1/',
        'https://tutoring-api.production.wgen.net/tutoring-collaborate-api/v1/'
    )
);


export const getLogoutLink = (redirect: string) => (
    getEnvValue(
        `https://my-devci.poc.learning.amplify.com/auth/realms/Amplify/protocol/openid-connect/logout?redirect_uri=${redirect}`,
        `https://amplify.com/auth/realms/Amplify/protocol/openid-connect/logout?redirect_uri=${redirect}`,
        `https://my.amplify.com/auth/realms/Amplify/protocol/openid-connect/logout?redirect_uri=${redirect}`
    )
);

export const getSecretKey = () => (
    getEnvValue(
        'N2MxNjkyOTYzODI5NDMxYTkwODk5N2I1ZDFiOWJhZGU=',
        'ODE0ZWM1Y2VmZmZkNDM0YTg5MzdhNjY2ODZjZmQ1MGI=',
        'MjZiMWFlMTRmZTE2NGM5N2E3MGJmZjM5Y2M4ODFjOTY='
    )
);