import {User} from "oidc-client-ts"
import {getOidcAuthority, getOidcClientId} from "../shared/config";
import {District, Enrollment} from "../services";
import { isEqual, isBefore } from 'date-fns';

export const getUser = () => {
    const oidcStorage = sessionStorage.getItem(
        `oidc.user:${getOidcAuthority()}:${getOidcClientId()}`
    );

    if (!oidcStorage) {
        return undefined;
    }

    return User.fromStorageString(oidcStorage);
};

export const removeQueryParamsFromURL = () => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    );
};

export const generateDistrictKey = (district?: District | null) => {
    if (district) {
        return `${district?.email}`;
    }

    return;
};

export const wrapFn = (fn: Function, ...params: any[]) => (() => {
    fn(...params);
});

export const sliceIntoChunks = <T>(arr: T[], chunkSize: number): Array<T[]> => {
    const res: Array<T[]> = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk: T[] = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export const sortGroupsBySessionTime = (a: Enrollment, b: Enrollment) => {
    if (a.nearestSession && b.nearestSession){
        if(isEqual(a.nearestSession.start_time, b.nearestSession.start_time)) return 0
        return isBefore(a.nearestSession.start_time, b.nearestSession.start_time) ? -1 : 1;
    }
    if(a.nearestSession && !b.nearestSession) {
        return -1
    }
    if(b.nearestSession && !a.nearestSession) {
        return 1
    }
    return 0;
}

export const sortGroupsByName = 
(a: Enrollment, b: Enrollment) => {
    if (
        a.course.title.toLowerCase() <
        b.course.title.toLowerCase()
    ) {
        return -1;
    }

    if (
        a.course.title.toLowerCase() >
        b.course.title.toLowerCase()
    ) {
        return 1;
    }

    return 0;
}

export const chunkArray = (arr: any[], size: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };