import { isToday } from "date-fns";
import { DistrictType } from "../components/Districts";
import { GetAllDistrictsResponse, NearestSession } from "../services";
import api from "../services/api";
import { sortGroupsBySessionTime, sortGroupsByName } from "./helpers";

export const getDistrictsData = async (email: string) => {
    const mapped_accounts = await api.bff.getEmails(email);
    if (mapped_accounts.data.results.length === 0) return [];

    api.tutoringApi.syncSubstitutes(mapped_accounts.data.results);

    const school_mapped_accounts = mapped_accounts.data.results.filter(
        (district: DistrictType) => district.inst_type === "School"
    );
    const uniq_account_user_ids: number[] = Array.from(
        new Set(
            school_mapped_accounts.map(
                (district: DistrictType) => district.amp_user_sid
            )
        )
    );

    const districts = mapped_accounts.data.results.reduce(
        (acc: DistrictType[], district: DistrictType) => {
            if (
                uniq_account_user_ids.includes(district.amp_user_sid) &&
                !acc.find(
                    (dist: DistrictType) =>
                        district.amp_user_sid === dist.amp_user_sid
                )
            ) {
                acc = [...acc, district];
            }

            return acc;
        },
        []
    );
    const districtsAmpUserSids = districts.map(
        (district: DistrictType) => district.amp_user_sid
    );

    api.tutoringApi.bbUserSessionsAsync(districtsAmpUserSids, email);

    const institutions = await api.bff.getInstitutions(
        districts.map((district: DistrictType) => district.inst_sid)
    );

    const results = districts
        .map((district: DistrictType, index: number) => {
            const districtName = institutions.data.results.find(
                (inst: any) => inst.institution_uid === district.inst_sid
            ).parent.institution_name;

            const districtUid = institutions.data.results.find(
                (inst: any) => inst.institution_uid === district.inst_sid
            ).parent.institution_uid;

            return {
                ...district,
                district_name: districtName,
                district_uid: districtUid,
                enrollments: [],
            };
        })
        .sort((a: DistrictType, b: DistrictType) => {
            if (a.district_name.toLowerCase() < b.district_name.toLowerCase()) {
                return -1;
            }

            if (a.district_name.toLowerCase() > b.district_name.toLowerCase()) {
                return 1;
            }

            return 0;
        });
    return results;
};

export const getEnrollmentsData = async (
    districts: GetAllDistrictsResponse
) => {
    const districtsAmpUserSids = districts.map(
        (district: DistrictType) => district.amp_user_sid
    );
    const enrollments = await api.tutoringApi.enrollments(districtsAmpUserSids);
    if(!enrollments.data.results.length) return {enrollmentsData: [], enrollmentsStoreData: {}}
    const districtsIds = Array.from(
        new Set(enrollments.data.results.map((res) => res.course.district_uid))
    );
    const [districtsDetails, nearestSessions] = await Promise.all([
        api.tutoringApi.getDistrictDetails(districtsIds),
        api.tutoringApi.getNearestSession(
            enrollments.data.results.map((data) => data.course.id)
        ),
    ]);
    const districtsTimezones: { id: string; timezone: string }[] =
        districtsDetails.data.results.map((district: any) => ({
            id: district.inst_uid,
            timezone: district.timezone,
        }));

    const enrollmentsData = enrollments.data.results.map((enrollment) => {
        const nearestSession = nearestSessions.data.results.find(
            (session: NearestSession) =>
                session.course_id === enrollment.course.id
        );
        return {
            ...enrollment,
            districtTimezone: districtsTimezones.find(
                (dist) => dist.id === `${enrollment.course.district_uid}`
            )?.timezone,
            nearestSession,
            hasSessionToday: isToday(
                new Date(nearestSession?.start_time + "Z")
            ),
        };
    });

    const dataWithNearestSession = enrollmentsData
        .filter((enrollment) => enrollment.nearestSession)
        .sort(sortGroupsBySessionTime);
    const dataWithoutNearestSession = enrollmentsData
        .filter((enrollment) => !enrollment.nearestSession)
        .sort(sortGroupsByName);
    const sortedEnrollmentsData = [
        ...dataWithNearestSession,
        ...dataWithoutNearestSession,
    ];

    let enrollmentsStoreData: any = {};

    districtsAmpUserSids.map((amp_user_sid) => {
        enrollmentsStoreData[amp_user_sid] = {
            loading: false,
            enrollments: sortedEnrollmentsData.filter(
                (enrollment) =>
                    enrollment.user_id === `urn:mclass-staff:${amp_user_sid}`
            ),
        };
        return null;
    });

    return { enrollmentsData: sortedEnrollmentsData, enrollmentsStoreData };
};
