import { format } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import './TimeComponent.scss';

const TimeComponent = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="time-container">
            <span className="time-container-date-title">{format(new Date(), 'EEE, MMM dd, yyyy')}</span>
            <div className="time-container-localtime-title">
            <div style={{position: 'relative', width: 25, height: 24, textAlign:'left', marginRight: 6}}>
                <i className="icon-v2-person" />
                <i className="icon-v2-clock-filled" style={{position:'absolute', fontSize: 14, width: 14, height: 14, bottom: 1, right: 0, background: 'white', borderRadius: '50%' }}/>
            </div>
                {format(currentTime, 'hh:mm aaaa (zzz)')} | YOUR LOCAL TIME
            </div>
        </div>
    );
};

export default TimeComponent