import { getEnv, Envs } from '../shared/config';

enum FeatureStatus {
    wip,
    done,
    onHold,
}

const features = {
    districts: {
        status: FeatureStatus.wip,
        finishedForEnvs: [Envs.Local, Envs.Ci],
    },
    displayCurrentTime: {
        status: FeatureStatus.wip,
        finishedForEnvs: [Envs.Local, Envs.Ci, Envs.Staging, Envs.Production],
    },
    nextSessionTime: {
        status: FeatureStatus.wip,
        finishedForEnvs: [Envs.Local, Envs.Ci, Envs.Staging, Envs.Production],
    },
    sortGroupsBySessionTime: {
        status: FeatureStatus.wip,
        finishedForEnvs: [Envs.Local, Envs.Ci, Envs.Staging, Envs.Production],
    },
    hmacAuthentication: {
        status: FeatureStatus.wip,
        finishedForEnvs: [Envs.Local, Envs.Ci, Envs.Staging, Envs.Production],
    },
};

export const isEnabled = (featureName: keyof typeof features) => {
    const feature = features[featureName];

    return (
        feature.status !== FeatureStatus.onHold &&
        (
            feature.status === FeatureStatus.done ||
            feature.finishedForEnvs.findIndex(env => env === getEnv()) !== -1
        )
    );
};
