import { isToday } from "date-fns";
import { format, formatInTimeZone } from "date-fns-tz";
import classnames from "classnames";
import { SessionCardProps } from ".";
import { isEnabled } from "../../utils/featureFlags";
import { useRef } from "react";

const initialText = "Next session details unavailable – check back soon"

const SessionCard: React.FC<SessionCardProps> = ({
    disabled,
    enrollment,
    action,
    kcAccessToken,
    targetAmpUserSid,
    targetUserType,
    redirectUri,
    onClick = () => undefined,
}) => {
    const btnRef = useRef<HTMLButtonElement>(null);
    let sessionDate: string =  initialText
    let localTime: string = "--";
    let districtTime: string = "--";
    let showTodaySign: boolean = false;

    if (isEnabled("nextSessionTime")) {
        if (enrollment.nearestSession?.start_time) {
            const startTimeDate = new Date(
                enrollment.nearestSession?.start_time + "Z"
            );
            showTodaySign = isToday(startTimeDate);
            sessionDate = format(startTimeDate, "EE, MMM dd, yyyy");
            localTime = format(startTimeDate, "hh:mm aaaa (zzz)");
            if (enrollment.districtTimezone)
                districtTime = formatInTimeZone(
                    startTimeDate,
                    enrollment.districtTimezone,
                    "hh:mm aaaa (zzz)"
                );
        }
    }

    const handleSubmit = () => {
        if (btnRef.current) btnRef.current.click();
    };
    return (
        // eslint-disable-next-line
        <form
            role="form"
            action={action}
            method="post"
            target="_blank"
            onSubmit={onClick}
        >
            <input
                type="hidden"
                id="kcAccessToken"
                name="kc_access_token"
                value={kcAccessToken}
            />
            <input
                type="hidden"
                id="targetAmpUserSid"
                name="target_amp_user_sid"
                value={targetAmpUserSid}
            />
            <input
                type="hidden"
                id="targetUserType"
                name="target_user_type"
                value={targetUserType}
            />
            <input
                type="hidden"
                id="redirectUri"
                name="redirect_uri"
                value={redirectUri}
            />
            <button
                type="submit"
                ref={btnRef}
                disabled={disabled}
                style={{ display: "none" }}
            />
            <div
                onClick={handleSubmit}
                className={classnames("tutoring-portal-session-card", {
                    hovered: !disabled,
                    "cursor-pointer": !disabled,
                })}
            >
                <div className="title-container">
                    <span
                        className={classnames("text-title", {
                            disabled: disabled,
                        })}
                    >
                        {enrollment.course?.title}
                    </span>
                    <span
                        className="text-small"
                        title={enrollment.course?.school_name}
                    >
                        {enrollment.course?.school_name}
                    </span>
                </div>
                {isEnabled("nextSessionTime") && (
                    <>
                        <div className="dates-container">
                            <span className={`text-normal ${sessionDate===initialText && 'text-italic'}`}>{sessionDate}</span>
                            <div className='next-session-time-container'>
                                <div
                                    className="date text-normal"
                                    title={"Local time"}
                                >
                                    <div style={{position: 'relative', width: 25, height: 24}}>
                                        <i className="icon-v2-person" />
                                        <i className="icon-v2-clock-filled" style={{position:'absolute', fontSize: 14, width: 14, height: 14, bottom: 1, right: 0, background: 'white', borderRadius: '50%' }}/>
                                    </div>
                                    <span>{localTime}</span>
                                </div>
                                <div
                                    className="date text-normal-light"
                                    title={"District time"}
                                >
                                    <div style={{ width: 25, height: 24, display:'flex', justifyContent:'center', alignItems:'center', paddingLeft: 4, paddingBottom: 0}}>
                                        <i className="date-icon icon-v2-school" />
                                    </div>
                                    <span>{districtTime}</span>
                                </div>
                            </div>
                        </div>
                        {showTodaySign && (
                            <span className="today-sign">TODAY</span>
                        )}
                    </>
                )}
            </div>
        </form>
    );
};

export default SessionCard;
