import React, { useEffect, useState } from "react";
import District from "./District";
import { useApi } from "../../hooks/useApi";
import { DistrictsProps, DistrictType } from ".";
import Loading from "../Loading/Loading";
import AmplifyToggle from "@amplify/amplify-toggle";
import EmptyDistrictsList from "./EmptyDistrictsList";
import { Enrollment, GetAllDistrictsResponse } from "../../services";
import { generateDistrictKey } from "../../utils/helpers";
import { keycloakInstance } from "../../providers/AuthOidcProvider";
import { useToastContext } from "../../contexts/useToastContext";
import { datadogLogs } from "@datadog/browser-logs";
import { useStore } from "../../providers/StoreProvider";
import SubstituteSection from "./SubstituteSection";
import {
    IS_TODAY_FILTER_TOGGLE_STATE,
    SUBSTITUTE_ID_KEY,
    SUBSTITUTE_SESSION_KEY,
    SUBSTTUTION_TOGGLE_STATE,
} from "../../shared/constants";
import "./Districts.scss";
import { getDistrictsData, getEnrollmentsData } from '../../utils/districts';
const THREE_MINUTES_IN_MILLISECONDS = 180000;

const Districts: React.FC<DistrictsProps> = ({
    email,
    accessToken,
    setLoaderScreenVisible,
    onLogout,
}) => {
    const { setError } = useToastContext();
    const {
        districts,
        enrollments,
        setDistricts,
        setEnrollments,
        activeDistrict,
        setActiveDistrict,
    } = useStore();
    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState(true);
    const [activeDistrictKey, setActiveDistrictKey] = useState<
        string | undefined
    >();
    const [isSubstitutesVisible, setIsSubstitutesVisible] = useState(
        sessionStorage.getItem(SUBSTTUTION_TOGGLE_STATE)
            ? JSON.parse(
                  sessionStorage.getItem(SUBSTTUTION_TOGGLE_STATE) as string
              )
            : true
    );
    const [isTodayFilterEnabled, setisTodayFilterEnabled] = useState(
        sessionStorage.getItem(IS_TODAY_FILTER_TOGGLE_STATE)
            ? JSON.parse(
                  sessionStorage.getItem(IS_TODAY_FILTER_TOGGLE_STATE) as string
              )
            : false
    );

    const { data: districtsWithoutEnrollments, loading } = useApi<GetAllDistrictsResponse>(async () => {
        try {
            const results = await getDistrictsData(email)

            let userInfo: null | any = null;
            if (keycloakInstance.authenticated) {
                try {
                    userInfo = await keycloakInstance.loadUserInfo();
                    const activeDistrict = results.filter(
                        (district: DistrictType) =>
                            district.email === userInfo.email
                    )[0];

                    if (sessionStorage.getItem(SUBSTITUTE_ID_KEY)) {
                        setActiveDistrict({
                            ...activeDistrict,
                            district_name: "substitute group",
                            email: SUBSTITUTE_SESSION_KEY,
                        });
                    } else {
                        setActiveDistrict(activeDistrict);
                    }
                } catch (error) {
                    datadogLogs.logger.error(
                        `${email} faced an issue during Amplify session user info loading`,
                        {
                            user_email: email,
                        },
                        error as Error
                    );
                    setActiveDistrict(null);
                }
            } else {
                setActiveDistrict(null);
            }

            setDistricts(results);

            return results;
        } catch (error) {
            datadogLogs.logger.error(
                `${email} faced an issue during data loading`,
                {
                    user_email: email,
                },
                error as Error
            );
            setError("Something happened!");
        }
    }, []);

    useApi(async () => {
        try {
            if (districtsWithoutEnrollments?.length) {
                const { enrollmentsData, enrollmentsStoreData } = await getEnrollmentsData(districtsWithoutEnrollments)
                setDistricts(districts => districts.map(district => {
                    district.enrollments = enrollmentsData.filter(enrollment => enrollment.user_id === `urn:mclass-staff:${district.amp_user_sid}` && enrollment.available && enrollment.role === "TUTOR") as Enrollment[]
                    return district
                    
                }))
                setEnrollments(enrollmentsStoreData)
                setIsLoading(false)
        }
        } catch (error) {
            datadogLogs.logger.error(
                `${email} faced an issue during enrollments loading`,
                {
                    user_email: email,
                },
                error as Error
            );
            setError("Something happened!");
            setIsLoading(false)
            return [];
        }

    }, [districtsWithoutEnrollments, updateData]);

    useEffect(() => {
        if (Object.keys(districts).length) return;
        setEnrollments((value) => {
            districts.forEach((item: any) => {
                value[item.amp_user_sid] = {
                    loading: true,
                    enrollments: [],
                };
            });

            return { ...value };
        });
    }, [districts, setEnrollments]);

    useEffect(() => {
        setActiveDistrictKey(activeDistrict?.email);
    }, [activeDistrict]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (document.visibilityState === "visible") {
                setUpdateData((value) => !value);
            }
        }, THREE_MINUTES_IN_MILLISECONDS);
        
        return () => {
            clearInterval(interval);
        };
    }, [])
    
    const onSubstitutesTogglerChanged = (event: any) => {
        if (event.target.checked) {
            sessionStorage.setItem(SUBSTTUTION_TOGGLE_STATE, "true");
            setIsSubstitutesVisible(true);
        } else {
            sessionStorage.setItem(SUBSTTUTION_TOGGLE_STATE, "false");
            setIsSubstitutesVisible(false);
        }
    };

    const onIsTodayFilterTogglerChanged = (event: any) => {
        if (event.target.checked) {
            sessionStorage.setItem(IS_TODAY_FILTER_TOGGLE_STATE, "true");
            setisTodayFilterEnabled(true);
        } else {
            sessionStorage.setItem(IS_TODAY_FILTER_TOGGLE_STATE, "false");
            setisTodayFilterEnabled(false);
        }
    };

    return (
        <div className="tutoring-portal-districts container">
            <div style={{ display: "flex", alignItems: "center", gap: 40 }}>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <AmplifyToggle
                        id="isTodayFilter"
                        enabledText="Yes"
                        disabledText="No"
                        labelText=""
                        onChange={onIsTodayFilterTogglerChanged}
                        value={isTodayFilterEnabled}
                    />
                    <div>Show today's groups only</div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <AmplifyToggle
                        id="isSubstitutesVisible"
                        enabledText="Yes"
                        disabledText="No"
                        labelText=""
                        onChange={onSubstitutesTogglerChanged}
                        value={isSubstitutesVisible}
                    />
                    <div>Show my substitute groups</div>
                </div>
            </div>

            <SubstituteSection
                enrollments={enrollments}
                email={email}
                accessToken={accessToken}
                setLoaderScreenVisible={setLoaderScreenVisible}
                activeAmpUserSid={activeDistrict?.amp_user_sid}
                isActive={activeDistrict?.email === SUBSTITUTE_SESSION_KEY}
                activeDistrictKey={activeDistrictKey}
                onLogout={onLogout}
                onRedirectLinkClick={() => {
                    setActiveDistrict(
                        () =>
                            ({
                                district_name: "substitute group",
                                email: SUBSTITUTE_SESSION_KEY,
                            } as DistrictType)
                    );
                }}
                isVisible={isSubstitutesVisible}
                isTodayFilterEnabled={isTodayFilterEnabled}
            />
            {loading ? (
                <Loading />
            ) : districts?.length ? (
                districts?.map((district) => {
                    const districtKey = generateDistrictKey(district);

                    return (
                        <District
                            key={districtKey}
                            email={email}
                            accessToken={accessToken}
                            district={district}
                            districts={districts}
                            districtKey={districtKey as string}
                            activeDistrictKey={activeDistrictKey}
                            onRedirectLinkClick={() =>
                                setActiveDistrict(district)
                            }
                            onLogout={onLogout}
                            setLoaderScreenVisible={setLoaderScreenVisible}
                            mappedAccountIds={districts.map(
                                ({ amp_user_sid }) => amp_user_sid
                            )}
                            isTodayFilterEnabled={isTodayFilterEnabled}
                            isLoading={isLoading}
                        />
                    );
                })
            ) : (
                <EmptyDistrictsList />
            )}
        </div>
    );
};

export default Districts;
