import React from "react";

import './Greetings.scss';
import TimeComponent from './TimeComponent';
import { isEnabled } from '../../utils/featureFlags';

const Greetings: React.FC<GreetingsProps> = ({ name }) => (
    <div className="container tutoring-portal-greetings">
        <div>
            <h2 className="font-merriweather">Hi, {name}!</h2>
            <p className="h4">
                Open tutoring groups or Educator Home for your districts.
            </p>
            <p className="h4">
                After your tutoring session, close your group and Educator Home tabs and remember to log out of the district from this page.
            </p>
        </div>
        {isEnabled('displayCurrentTime') && 
        <TimeComponent/>}
    </div>
)

export default Greetings;
